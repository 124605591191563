import React, { useContext, useEffect } from 'react'
import DeleteErrorBanner from '../../components/Banners/DeleteErrorBanner'
import DeleteSuccessBanner from '../../components/Banners/DeleteSuccessBanner'
import GenericErrorBanner from '../../components/Banners/GenericErrorBanner'
import RecoverErrorBanner from '../../components/Banners/RecoverErrorBanner'
import RecoverSuccessBanner from '../../components/Banners/RecoverSuccessBanner'
import UnlinkErrorBanner from '../../components/Banners/UnlinkErrorBanner'
import UnlinkSuccessBanner from '../../components/Banners/UnlinkSuccessBanner'
import BannerContext from '../../contexts/BannerContext'
import UserContext from '../../contexts/UserContext'
import { isDateInTheFuture } from '../../utils/dateUtils'

const BannerSection = () => {
  const activeBannerComponent = getBannerComponent()
  const { userData } = useContext(UserContext)
  const { bannerContent, setBannerContent } = useContext(BannerContext)

  const isAbleToSetDeleteBanner =
      bannerContent === null
      && userData?.expirationDate
      && isDateInTheFuture(userData.expirationDate)

  useEffect(() => {
    isAbleToSetDeleteBanner && setBannerContent('deleteSuccess')
  }, [userData?.expirationDate, bannerContent])

  return (
    <div>
      {activeBannerComponent !== null && activeBannerComponent}
    </div>
  )
}

const getBannerComponent = () => {
  const { bannerContent } = useContext(BannerContext)

  switch(bannerContent) {
    case 'deleteSuccess':
      return <DeleteSuccessBanner />
    case 'deleteError':
      return <DeleteErrorBanner />
    case 'recoverSuccess':
      return <RecoverSuccessBanner />
    case 'recoverError':
      return <RecoverErrorBanner />
    case 'unlinkSuccess':
      return <UnlinkSuccessBanner />
    case 'unlinkError':
      return <UnlinkErrorBanner />
    case 'genericError':
      return <GenericErrorBanner />
    default:
      return null
  }
}

export default BannerSection