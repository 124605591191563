import React from 'react'
import { usePreservingNavigate } from '../../utils/preservingNavigation'
import '@stillfront/stillfront-component-library/dist/utilities/container.scss'
import styles from './NavigationBar.module.scss'
import { ROUTES } from '../ContentSection/ContentSection'
import { Button } from '@stillfront/stillfront-component-library'

const NavigationBar = () => {
  const navigate = usePreservingNavigate()

  const getNavigationObject = () => {
    switch (location.pathname) {
      case ROUTES.ACCOUNT:
        return {
          title: 'Account Settings',
          handleBack: () => navigate(ROUTES.HOME),
        }
      case ROUTES.PRIVACY:
        return {
          title: 'Privacy and Communication',
          handleBack: () => navigate(ROUTES.ACCOUNT),
        }
      case ROUTES.MANAGE_ACCOUNTS:
        return {
          title: 'Manage Game Accounts',
          handleBack: () => navigate(ROUTES.ACCOUNT),
        }
      case ROUTES.DELETE_ACCOUNT:
        return {
          title: 'Account Deletion',
          handleBack: () => navigate(ROUTES.PRIVACY),
        }
      case ROUTES.TERMS_AND_CONDITIONS:
        return {
          title: 'Privacy and T&Cs',
          handleBack: () => navigate(ROUTES.PRIVACY),
        }
      default:
        return null
    }
  }

  const navigationObject = getNavigationObject()

  return <>
    {navigationObject
      ? (
        <div className={`stillfront-content-container ${styles.navigationBar}`}>
          <Button variant="icon" onClick={navigationObject.handleBack} icon='back'/>
          <h4 className={styles.navigationBarTitle}>{navigationObject.title}</h4>
        </div>
      )
      : <div/>
    }
  </>
}

export default NavigationBar
