import React from 'react'
import FeaturedGames from '../../components/FeaturedGames/FeaturedGames'
import MyGames from '../../components/MyGames/MyGames'
import RecommendedGames from '../../components/RecommendedGames/RecommendedGames'
import styles from './HomeContent.module.scss'

const HomeContent = () => {
  return (
    <div className={`stillfront-container ${styles.container}`}>
      <FeaturedGames />
      <MyGames />
      <RecommendedGames />
    </div>
  )
}

export default HomeContent