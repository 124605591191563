import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.scss'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@stillfront/stillfront-component-library/dist/main.scss'
import { useTour } from './contexts/TourContext'
import { useContext, useEffect } from 'react'
import UserContext from './contexts/UserContext'
import Header from './appSections/Header/Header'
import BannerSection from './appSections/BannerSection/BannerSection'
import ContentSection from './appSections/ContentSection/ContentSection'
import ModalSection from './appSections/ModalSection/ModalSection'
import CookieConsent from './components/CookieConsent/CookieConsent'
import Providers from './contexts/Providers'
import { checkSession } from './utils/session'
import { Footer } from '@stillfront/stillfront-component-library'

const AppLayout = () => {
  const { startTour } = useTour()
  const { loading } = useContext(UserContext)
  const isActiveTour = new URLSearchParams(location.search).get('tour')

  useEffect(() => {
    !loading && isActiveTour && startTour()
  }, [loading])

  window.addEventListener('pageshow', async (event) =>
    event.persisted && await checkSession()
  )

  return (
    <div className="app dark">
      <Header/>
      <BannerSection/>
      <ContentSection>
        <ModalSection/>
      </ContentSection>
      <CookieConsent/>
      <Footer />
    </div>
  )
}

const App = () => {
  console.log('Commit: ', process.env.REACT_APP_COMMIT_SHA)

  return (
    <Router>
      <Providers>
        <AppLayout/>
      </Providers>
    </Router>
  )
}

export default App
