import React, { useContext, useEffect, useState } from 'react'
import styles from './TourOverlay.module.scss'
import { Button } from '@stillfront/stillfront-component-library'
import { TourContext } from '../../contexts/TourContext'
import { calculatePopUpPosition } from './calculateTourPopUpPosition'
import { usePreservingNavigate } from '../../utils/preservingNavigation'

export const TourOverlay: React.FC = () => {
  const { steps, currentStepIndex, nextStep, prevStep, endTour } = useContext(TourContext)!
  const navigate = usePreservingNavigate()
  const step = steps[currentStepIndex]
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null)
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({})

  useEffect(() => {
    const fetchAndHighlightElement = async () => {
      const element = document.querySelector<HTMLElement>(`[data-tour="${step.selector}"]`)
      setTargetElement(element)
    }

    fetchAndHighlightElement()
  }, [step, navigate])

  useEffect(() => {
    window.location.pathname !== step.path
      && navigate(step.path)
  }, [targetElement, navigate, step])

  useEffect(() => {
    const updatePopUpPosition = () => {
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect()
        targetElement.classList.add(styles.highlight)

        const tooltipPosition = calculatePopUpPosition(rect, step.placement, styles.tourPopUp)
        setTooltipStyle({
          position: 'absolute',
          ...tooltipPosition
        })
      }
    }

    updatePopUpPosition()
    window.addEventListener('resize', updatePopUpPosition)

    return () => {
      window.removeEventListener('resize', updatePopUpPosition)
      targetElement?.classList.remove(styles.highlight)
    }
  }, [targetElement, step])

  return (
    <div className={styles.tourOverlay}>
      <div className={styles.tourPopUp} style={tooltipStyle}>
        {step.content}
        <div className={styles.navigationButtons}>
          {currentStepIndex > 0 && (
            <Button size="small" onClick={prevStep}>Previous</Button>
          )}
          {currentStepIndex < steps.length - 1
            ? <Button size="small" onClick={nextStep}>Next</Button>
            : <Button size="small" onClick={endTour}>End Tour</Button>
          }
        </div>
      </div>
    </div>
  )
}