import React from 'react'

export const calculatePopUpPosition = (
  rect: DOMRect,
  placement: 'top' | 'bottom' | 'left' | 'right',
  tourPopUpQuerySelector: string
): React.CSSProperties => {
  const paddingY = 10
  const paddingX = 20
  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight

  let top = 0
  let left = 0
  let transform = ''

  const popUp = document.querySelector(`.${tourPopUpQuerySelector}`)
  const popUpRect = popUp?.getBoundingClientRect() || { width: 0, height: 0 }

  switch (placement) {
    case 'bottom':
      top = rect.bottom + paddingY
      left = rect.left + (rect.width / 2)
      transform = 'translateX(-50%)'
      break
    case 'top':
      top = rect.top - popUpRect.height - paddingY
      left = rect.left + (rect.width / 2)
      transform = 'translateX(-50%)'
      break
    case 'left':
      top = rect.top + (rect.height / 2)
      left = rect.left - popUpRect.width - paddingX
      transform = 'translateY(-50%)'
      break
    case 'right':
      top = rect.top + (rect.height / 2)
      left = rect.right + paddingX
      transform = 'translateY(-50%)'
      break
  }

  // Correcting position if PopUp goes out of viewport
  if (left + popUpRect.width / 2 > viewportWidth) {
    left = viewportWidth - (popUpRect.width / 2) - paddingX
  } else if (left - popUpRect.width / 2 < 0) {
    left = popUpRect.width / 2 + paddingX
  }

  if (top + popUpRect.height > viewportHeight) {
    top = viewportHeight - popUpRect.height - paddingY
  } else if (top < 0) {
    top = paddingY
  }

  return {
    position: 'absolute',
    top: `${top}px`,
    left: `${left}px`,
    transform
  }
}