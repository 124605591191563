import React, { useContext } from 'react'
import profileLogo from '../../assets/images/profile-icon.svg'
import { usePreservingNavigate } from '../../utils/preservingNavigation'
import UserContext from '../../contexts/UserContext'
import { Button } from '@stillfront/stillfront-component-library'
import styles from './AccountSettings.module.scss'
import { ROUTES } from '../../appSections/ContentSection/ContentSection'

const AccountSettings = () => {
  const { userData } = useContext(UserContext)
  const navigate = usePreservingNavigate()

  const handlePrivacyClick = () => navigate(ROUTES.PRIVACY)
  const handleManageAccounts = () => navigate(ROUTES.MANAGE_ACCOUNTS)
  const handleSignOut = () => window.location.href = `${process.env.REACT_APP_ACCOUNT_BACKEND_BASE_URL}/sign-out`

  const infoValues: {key: string, value: string}[] = [
    { key: 'Profile Name', value: userData && userData.userName ? userData.userName : 'placeholder' },
  ]

  return (
    <div className={`stillfront-content-container ${styles.container}`}>
      <img src={profileLogo} data-tour="firstStep" className={styles.profileLogo} alt="Profile logo" />
      <div>
        <h5 className={styles.infoCardHeading}>Your Information</h5>
        <div className={styles.infoCard}>
          {infoValues.map((infoValue, index) => (
            <div key={index}>
              <h5>{infoValue.key}:</h5>
              <p className="body-2">{infoValue.value}</p>
            </div>
          )
          )}
        </div>
      </div>
      <div className={styles.buttonGroup}>
        <Button variant="contained" onClick={handlePrivacyClick}>Privacy & Communication</Button>
        <Button data-tour="secondStep" variant="outlined" onClick={handleManageAccounts}>Manage Game Accounts</Button>
        <Button variant="outlined" onClick={handleSignOut}>Sign Out</Button>
      </div>
    </div>
  )
}

export default AccountSettings
