import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styles from './Header.module.scss'
import profileLogo from '../../assets/images/profile-icon.svg'
import logo from '../../assets/images/Stillfront-logo.svg'
import UserContext from '../../contexts/UserContext'
import { getCleanUserName } from '../../utils/userNameUtils'

const HeaderLogo = () => {
  return (
    <Link to="/" className={styles.headerLogo}>
      <img
        src={logo}
        alt="Stillfront logo"
      />
    </Link>
  )
}

const Avatar = () => {
  const { userData } = useContext(UserContext)

  const cleanUserName: string = userData && userData.userName
    ? getCleanUserName(userData.userName)
    : 'placeholder'

  return (
    <Link to="/account" className={styles.headerAvatar}>
      <div className={styles.userTitle}>
        <p className="body-1-bold">{cleanUserName}</p>
      </div>
      <img
        src={profileLogo}
        className={styles.squareLogo}
        alt="Profile logo"
      />
    </Link>
  )
}

const Header = () => {
  return (
    <div className={styles.customNavbar}>
      <HeaderLogo />
      <Avatar />
    </div>
  )
}

export default Header
