import { gamesMock } from './games'
import { UserResponse } from '../api/responses/userResponse'
import { getThirtyDaysInTheFuture } from '../utils/dateUtils'

export const userMock: UserResponse = {
  user: {
    sfId: '0f5c5d8b-2d71-4c0f-b0e5-9e81aab4f561',
    userName: 'schnellerLöwe#1947',
    acceptedMarketing: true,
    acceptedNotifications: true,
    acceptedTerms: true,
    creationDate: 0,
    deletionDate: 0,
    expirationDate: getThirtyDaysInTheFuture(),
    expired: 1,
    language: 'de_DE',
    reachedRequiredAge: true
  },
  games: gamesMock
}