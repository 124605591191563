import React, { ReactNode } from 'react'
import { BannerProvider } from './BannerContext'
import { ModalProvider } from './ModalContext'
import { UserProvider } from './UserContext'
import { GameProvider } from './GameContext'
import { TourProvider } from './TourContext'
import { ROUTES } from '../appSections/ContentSection/ContentSection'
import { TourStep } from '../appSections/Tour/types'

const Providers = ({ children }: {children: ReactNode}) => {
  return (
    <UserProvider>
      <GameProvider>
        <BannerProvider>
          <ModalProvider>
            <TourProvider steps={steps}>
              {children}
            </TourProvider>
          </ModalProvider>
        </BannerProvider>
      </GameProvider>
    </UserProvider>
  )
}

const steps: TourStep[] = [
  {
    selector: 'firstStep',
    content: 'This is our Stillfront Logo!',
    placement: 'bottom',
    path: ROUTES.ACCOUNT
  },
  {
    selector: 'secondStep',
    content: 'Here you can change your profile.',
    placement: 'bottom',
    path: ROUTES.ACCOUNT
  }
]

export default Providers